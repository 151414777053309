import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth, db } from "../../config/firebaseSetup";
import {
  arrayUnion,
  addDoc,
  collection,
  doc,
  onSnapshot,
  writeBatch,
  query,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";
import "../../styles/conversationsStyles.css";
import InlineLoader from "../InlineLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sendIcon from "../../assets/icons/sendIcon.svg";

const AdminConversationView = ({ conversationId }) => {
  // const { conversationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const currentUser = auth.currentUser;

  useEffect(() => {
    setMessages([]);
  }, [conversationId]);

  useEffect(() => {
    if (!conversationId) return;

    const messagesRef = collection(
      db,
      "conversations",
      conversationId,
      "messages"
    );
    const messagesQuery = query(messagesRef, orderBy("timestamp", "asc")); // Order by timestamp ascending
    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const fetchedMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(fetchedMessages);
    });

    return () => unsubscribe();
  }, [conversationId]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    const messagesRef = collection(
      db,
      "conversations",
      conversationId,
      "messages"
    );
    const batch = writeBatch(db);

    try {
      // First, mark all unread messages sent by users as read
      const unreadMessagesQuery = query(
        messagesRef,
        where("read", "==", false),
        where("senderId", "!=", currentUser.uid)
      );
      const unreadSnapshot = await getDocs(unreadMessagesQuery);
      unreadSnapshot.docs.forEach((doc) => {
        batch.update(doc.ref, { read: true });
      });

      // Then, add the new message
      const newMessageRef = doc(messagesRef);
      const newMessageData = {
        senderId: currentUser.uid,
        message: newMessage,
        timestamp: Date.now(),
        read: false,
        sentByAdmin: true,
      };
      batch.set(newMessageRef, newMessageData);

      // Update the mostRecentMessage field in the conversations document
      const conversationRef = doc(db, "conversations", conversationId);
      batch.update(conversationRef, {
        mostRecentMessage: newMessageData,
        lastUpdated: Date.now(),
      });

      // Commit the batch
      await batch.commit();
      setNewMessage("");
    } catch (error) {
      console.error("Unable to send message or update read status:", error);
    }
  };

  return (
    <div>
      <div className="allMessagesView">
        {!messages.length ? (
          <InlineLoader />
        ) : (
          messages.map((message) => (
            <div key={message.id}>
              <p
                className={`${
                  message.senderId === currentUser.uid
                    ? "userMessage"
                    : "adminMessage"
                }`}
              >
                {message?.message}
              </p>
            </div>
          ))
        )}
      </div>
      <div className="sendMessage">
        <input
          type="text"
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button className="sendMessageInput" onClick={handleSendMessage}>
          <img src={sendIcon} alt="" />
        </button>
      </div>
    </div>
  );
};

export default AdminConversationView;
