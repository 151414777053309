import { useEffect, useState } from "react";
import { auth, db } from "../../config/firebaseSetup";
import { Link } from "react-router-dom";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import StartNewChat from "./StartNewChat";
import AdminConversationView from "./AdminConversationView";
import InlineLoader from "../InlineLoader";

const AdminConversationList = () => {
  const currentUser = auth.currentUser;
  const [conversations, setConversations] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) return;
    setLoading(true);
    try {
      const conversationsCollection = collection(db, "conversations");
      const q = query(
        conversationsCollection,
        where("assignedAdmins", "array-contains", currentUser.uid),
        orderBy("lastUpdated", "desc")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const conversations = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setConversations(conversations);
      });

      return () => unsubscribe();
    } catch (error) {
      console.error("Failed to fetch conversations", error);
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  if (loading) {
    return <InlineLoader />;
  }

  return (
    <div>
      <div className="messageFlex">
        <h2>All Conversations</h2>
        <StartNewChat setSelectedMessage={setSelectedMessage} />
      </div>
      <div className="messagesContainer">
        {!conversations.length ? (
          <h2 style={{ marginTop: "10px" }}>No Messages yet</h2>
        ) : (
          <>
            {" "}
            <div className="messages-list">
              {conversations
                .filter((conversation) =>
                  conversation?.assignedAdmins?.includes(currentUser.uid)
                )
                .map((conversation) => (
                  <div
                    key={conversation.id}
                    className={`singleMessageItem ${
                      conversation?.id === selectedMessage && "messageActive"
                    }`}
                    onClick={() => setSelectedMessage(conversation?.id)}
                    // to={`/admin/conversation/${conversation?.id}`}
                  >
                    <div className="flexContainerMessagesList">
                      <div className="emailAndMessage">
                        <span className="messageEmail">
                          {conversation.participants[0]}
                        </span>
                        <span className="messageMessage">
                          {conversation?.mostRecentMessage?.message}
                        </span>
                      </div>
                      {conversation?.mostRecentMessage?.sentByAdmin ? null : (
                        <div className="unreadMessageFromUser"></div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            <div>
              {selectedMessage ? (
                <AdminConversationView conversationId={selectedMessage} />
              ) : (
                <p className="defaultMessage">Please Select A Message</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminConversationList;
