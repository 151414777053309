import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../config/firebaseSetup";
import {
  collection,
  addDoc,
  query,
  where,
  writeBatch,
  getDocs,
  doc,
} from "firebase/firestore";
import Button from "../Button";
import { toast } from "react-toastify";

const StartNewChat = ({ setSelectedMessage }) => {
  const [userEmail, setUserEmail] = useState("");
  const [newConversation, setNewConversation] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleStartChat = async () => {
    if (!userEmail) return;
    setLoading(true);

    try {
      const userRef = collection(db, "users");
      const q = query(userRef, where("email", "==", userEmail));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0].data();
        const newConversationRef = await addDoc(
          collection(db, "conversations"),
          {
            participants: [userDoc.email, userDoc.uid, auth.currentUser.uid],
            assignedAdmins: [auth.currentUser.uid],
            lastUpdated: Date.now(),
          }
        );

        const messagesRef = collection(
          db,
          "conversations",
          newConversationRef.id,
          "messages"
        );
        const batch = writeBatch(db);

        const newMessageRef = doc(messagesRef);
        const newMessageData = {
          senderId: auth.currentUser.uid,
          message: message,
          timestamp: Date.now(),
          read: false,
          sentByAdmin: true,
        };

        batch.set(newMessageRef, newMessageData);

        // Update the mostRecentMessage field in the conversations document
        const conversationRef = doc(db, "conversations", newConversationRef.id);
        batch.update(conversationRef, {
          mostRecentMessage: newMessageData,
          lastUpdated: Date.now(),
        });

        await batch.commit();
        setSelectedMessage(newConversationRef.id);
        setUserEmail("");
        // navigate(`/admin/conversation/${newConversationRef.id}`);
      } else {
        toast.error("No user found with this email");
      }
    } catch (error) {
      toast.error("Error starting a new chat.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="modal"
      style={{
        position: "relative",
      }}
    >
      <Button onClick={() => setNewConversation((prev) => !prev)}>
        Start a new Conversation
      </Button>
      {newConversation && (
        <div className="modal-content-cc">
          <input
            type="email"
            placeholder="Enter User Email To Start A New Chat"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            disabled={loading}
          />
          <input
            type="text"
            placeholder="Enter Message To Send To User"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            disabled={loading}
          />
          <Button onClick={handleStartChat} disabled={loading}>
            {loading ? "Starting..." : "Start Chat"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default StartNewChat;
