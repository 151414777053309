import ReactDOM from "react-dom";

import SubAdminForm from "./SubAdminForm";
import { useForm } from "react-hook-form";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../../config/firebaseSetup";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import axios from "axios";

const CreateSubAdmin = ({ onClose, setLoading, getAdmins }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const initialFormData = {
    auctionsActions: {
      canApprove: false,
      canDelete: false,
      canEdit: false,
      canTrash: false,
      canSuspend: false,
    },
    tagsActions: {
      canApprove: false,
      canCreate: false,
      canDelete: false,
      canEdit: false,
      canPending: false,
      canTrash: false,
    },
    usersActions: {
      canApprove: false,
      canDelete: false,
      canSuspend: false,
    },
    categoriesActions: {
      canCreate: false,
      canDelete: false,
      canEdit: false,
    },
    adminActions: {
      canCreate: false,
      canDelete: false,
      canEdit: false,
    },
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      const apiKey = "AIzaSyBg3nh6dphlyfUUxT1ACGLA4qdWiUrEdIo";

      const url = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${apiKey}`;

      const res = await axios.post(url, {
        email: data.email,
        password: data.password,
        returnSecureToken: false,
      });

      const user = res.data;

      await setDoc(doc(db, "admins", user.localId), {
        uid: user.localId,
        isAdmin: true,
        ...data,
        password: null,
      });

      toast.success("Sub admin created successfully");
      getAdmins();
      onClose();
    } catch (error) {
      toast.error("Error creating sub-admin: " + error.message);
    } finally {
      setLoading(false);
    }
  });
  return ReactDOM.createPortal(
    <div className="formModal-container">
      <div className="formModal-content">
        <h2>Create Sub Admin</h2>
        <button onClick={onClose} className="cancel-auction-creation">
          Close
        </button>
      </div>
      <div className="formModal-content">
        <SubAdminForm
          onSubmit={onSubmit}
          errors={errors}
          initialFormData={initialFormData}
          control={control}
        />
      </div>
    </div>,
    document.querySelector("#subAdminFormModal")
  );
};

export default CreateSubAdmin;
